import Footer from "components/layout/footer";
import Header from "components/layout/header";
import Menu from "components/layout/menu";
import ImagePortableText from "components/portableText/image.portableText";
import PageMeta from "components/layout/pageMeta";

import PortableText from "components/portableText/portableText";
import { globalQueries, portableTextQuery } from "helpers/sanityQueries";
import { client } from "helpers/sanity";
import { GetStaticProps } from "next";
import { PreviewRequirements } from "../preview";

const Tool = props => {
  const {
    settings,
    doc,
    doc: {
      title = "Ingen tittel",
      lede,
      formSwitch,
      portableText,
      image,
      illustration,
    },
    menu,
    contact,
    ads,
  } = props;

  return (
    <>
      <PageMeta doc={doc} settings={settings} />
      <Menu menu={menu} />
      <Header
        type="white"
        title={title}
        lede={lede}
        displayContactForm={formSwitch}
      />
      <article className="u-bg--lightgrey">
        {image && image.asset && (
          <section className="o-wrapper">
            <ImagePortableText image={image.asset} caption={image.caption} />
          </section>
        )}
        {portableText && (
          <section className="o-wrapper u-padding-top-bottom">
            <PortableText blocks={portableText} width="narrow" />
          </section>
        )}
      </article>

      <Footer contact={contact} settings={settings} />
    </>
  );
};

export const getStaticProps: GetStaticProps<PreviewRequirements> = async ({
  params,
}) => {
  if (!params) {
    throw new Error("Missing params, should never happen.");
  }
  const { slug = "" } = params;
  const groqParams = { slug };
  const groqQuery = {
    doc: `*[_type == "tool" && slug.current == $slug && !(_id in path("drafts.*"))][0]
    {
      _id,
      _type,
      title,
      lede,
      formSwitch,
      slug,
      ${portableTextQuery},
      image{ ..., asset-> },
      illustration{ ..., asset-> },
    }`,
    ...globalQueries,
  };

  const result = await client.fetch(groqQuery, groqParams);

  if (!result || !result.doc || Object.keys(result.doc).length === 0) {
    return { notFound: true, props: { groqQuery, groqParams } };
  }
  return { props: { ...result, groqQuery, groqParams } };
};

export async function getStaticPaths() {
  const docs = await client.fetch(
    `*[_type == "tool" && !(_id in path('drafts.**'))][0...10000]{slug}`
  );
  const paths = docs.map(doc => ({ params: { slug: doc.slug.current } }));
  return { paths, fallback: "blocking" };
}

export default Tool;
